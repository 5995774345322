import React from 'react';
import './Congratulations.scss';


const Congratulations = () => {
  return (
    <div className="congratulations-container">
      <div className="congratulations-content">
        <h1 className="title">Recebemos a sua mensagem!</h1>
        <p className="message">
          Agora é com a gente. <br />
          Em breve, nossa equipe entrará em contato para agendar uma conversa com você.
        </p>
        <p className="cta-message">
          Enquanto isso, que tal nos visitar? <br />
          <a href="https://www.mastertech.com.br" target="_blank" rel="noopener noreferrer">
            Acesse www.mastertech.com.br
          </a>
          <br />
          e confira os cases incríveis que temos o prazer de compartilhar.
        </p>
      </div>
    </div>
  );
}

export default Congratulations;
