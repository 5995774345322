import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import Navbar from "./components/ui/Navbar/Navbar";
import Congratulations from "./pages/Congratulations/Congratulations";
import LandingPage from "./pages/LandingPage/LandingPage";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <>
              <Navbar />
              <LandingPage />
            </>
          } />
          <Route path="/parabens" element={
            <>
              <Navbar />
              <Congratulations />
            </>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
