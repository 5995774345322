import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Navbar.scss';

const Navbar = ({ inverted, sendToBack, openCart, setOpenCart }) => {
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const switchMenuMobileisOpen = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const handleSubmenuToggle = (submenu) => {
    setActiveSubmenu(prevState => prevState === submenu ? null : submenu);
  };


  const submenuItems = {
    servicos: [
      { href: "/workshops-e-palestras", label: "Workshops e Palestras" },
      { href: "/trilhas-e-imersoes", label: "Programas e Trilhas" },
      { href: "/consultoria-de-inovacao", label: "Consultoria de Inovação" },
    ],
    teamBuilding: [
      { href: "/team-building-fabrica-de-bikes", label: "A Fábrica de Bikes" },
      { href: "/team-building-online", label: "Mission: EVE" },
      { href: "/malabarismo-agil", label: "Malabarismo Ágil" },
    ],
    outrosServicos: [
      { href: "/academy", label: "Academy" },
      { href: "/coworkings", label: "Coworkings" },
      { href: "/portal-de-vagas", label: "Portal de Vagas" },
    ],
  };

  return (
    <div className="navbar-container">
      <nav className={`Navbar ${sendToBack ? "back" : ""} `}>
        <div className="header-navbar">
          <a href={window.location.origin}>
            <h1>mastertech</h1>
          </a>
        </div>
      </nav>
    </div>
  )
}

export default Navbar;
