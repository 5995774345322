import React, { useState } from 'react';
import OneImage from '../../assets/images/landingPage/one.png';
import ThreeImage from '../../assets/images/landingPage/three.png';
import TwoImage from '../../assets/images/landingPage/two.png';
import './LandingPage.scss';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  // Função para fechar o popup ao clicar fora
  const handleOutsideClick = (e) => {
    if (e.target.className === "popup-overlay") {
      setShowPopup(false);
    }
  };


  const [formData, setFormData] = useState({
    nome: "",
    empresa: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome completo é obrigatório.";
    if (!formData.empresa.trim()) newErrors.empresa = "Empresa é obrigatória.";
    if (!formData.email.trim()) {
      newErrors.email = "E-mail é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "E-mail inválido.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const response = await fetch("https://webhook.sellflux.app/webhook/custom/lead/9fb39fd910a6708e156d228c541bb278?name=name&email=email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.nome,
          email: formData.email,
          enterprise: formData.empresa,
        }),
      });

      if (response.ok) {
        navigate('/parabens');
        setLoading(false);
      }

      setFormData({
        nome: "",
        empresa: "",
        email: "",
      });
      setErrors({});
    } else {
      setLoading(false);
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <section className='LandingPage'>
        <div className="header">
          <div className="left">
            <h1>Aumente a produtividade <br /> do seu time com<br /> <span>inteligência artificial.</span></h1>
            <p>Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.</p>
          </div>
          <div className="right">
            <div className="container-contact">
              <form className="custom-form" onSubmit={handleSubmit}>
                <div className="form-row">
                  <div
                    className={`form-group ${errors.nome ? "error" : ""}`}
                  >
                    <input
                      type="text"
                      name="nome"
                      placeholder="NOME COMPLETO"
                      value={formData.nome}
                      onChange={handleChange}
                    />
                    {errors.nome && <span className="error">{errors.nome}</span>}
                  </div>

                  <div
                    className={`form-group ${errors.email ? "error" : ""}`}
                  >
                    <input
                      type="email"
                      name="email"
                      placeholder="SEU E-MAIL COMERCIAL"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                  </div>

                  <div
                    className={`form-group ${errors.empresa ? "error" : ""}`}
                  >
                    <input
                      type="text"
                      name="empresa"
                      placeholder="EMPRESA"
                      value={formData.empresa}
                      onChange={handleChange}
                    />
                    {errors.empresa && <span className="error">{errors.empresa}</span>}
                  </div>
                </div>

                <div className="form-row">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn-enviar"
                  >
                    {loading ? "ENVIANDO..." : "INSCREVA-SE AGORA"}
                  </button>
                </div>

                <div className="text-container">
                  <p>INSCREVA-SE E GANHE <br /> UMA CONVERSA <span>GRATUITA</span> <br /> COM O NOSSO TIME.</p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="learning-section">
          <h2 className="learning-section__title">O QUE VOCÊ VAI APRENDER</h2>
          <div className="learning-section__content">
            <div
              className="learning-section__item learning-section__item--left"
            >
              <div className="learning-section__item-learning-box">
                <h3 className="learning-section__item-title">
                  Fundamentos <br /> <span>do ChatGPT</span>
                </h3>
                <p className="learning-section__item-description">
                  Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.
                </p>
              </div>
              <img src={OneImage} alt="Primeira imagem" />
              <div className="dot" />
            </div>

            <div
              className="learning-section__item learning-section__item--right"
            >
              <div className="learning-section__item-learning-box">
                <h3 className="learning-section__item-title">
                  Uso avançado <br /> <span>do ChatGPT</span>
                </h3>
                <p className="learning-section__item-description">
                  Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados.
                </p>
              </div>
              <img src={TwoImage} alt="Segunda imagem" />
              <div className="dot" ></div>
            </div>

            <div
              className="learning-section__item learning-section__item--left"
            >
              <div className="learning-section__item-learning-box">
                <h3 className="learning-section__item-title">
                  Exploração <br /> <span>de possibilidades</span>
                </h3>
                <p className="learning-section__item-description">
                  Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini.
                </p>
              </div>
              <img src={ThreeImage} alt="Terceira imagem" />
              <div className="dot" ></div>
            </div>
            <p
              className="text-flutuant"
              animate={{
                x: 0,
                opacity: 1,
                transform: "matrix(0, -1, 1, 0, 0, 0)"
              }}
              exit={{
                x: "-20vw",
                opacity: 0,
                transform: "matrix(0, -1, 1, 0, 0, 0)"
              }}
            >
              gen IA
            </p>

          </div>
          <div className="duration-section">
            <div className="duration">
              <p
                className="duration-title"
              >
                DURAÇÃO
              </p>
              <p
                className="duration-content"
              >
                3 encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.
              </p>
            </div>

            <div className="duration">
              <p
                className="duration-title"
              >
                EXPERIÊNCIA
              </p>
              <p
                className="duration-content"
              >
                Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.
              </p>
            </div>

            <div className="duration">
              <p
                className="duration-title"
              >
                PARTICIPANTES
              </p>
              <p
                className="duration-content"
              >
                Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-form">
        <div className="contact">
          <div className="left">
            <h2>Quer aumentar a produtividade <br /> do seu time com inteligência artificial?</h2>
            <p>INSCREVA-SE E GANHE <br /> UMA CONVERSA <span>GRATUITA</span> <br /> COM O NOSSO TIME.</p>
          </div>
          <div className="right">
            <div className="container-contact">
              <form className="custom-form" onSubmit={handleSubmit}>
                <div className="form-row">
                  <div
                    className={`form-group ${errors.nome ? "error" : ""}`}
                  >
                    <input
                      type="text"
                      name="nome"
                      placeholder="NOME COMPLETO"
                      value={formData.nome}
                      onChange={handleChange}
                    />
                    {errors.nome && <span className="error">{errors.nome}</span>}
                  </div>

                  <div
                    className={`form-group ${errors.email ? "error" : ""}`}
                  >
                    <input
                      type="email"
                      name="email"
                      placeholder="SEU E-MAIL COMERCIAL"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                  </div>

                  <div
                    className={`form-group ${errors.empresa ? "error" : ""}`}
                  >
                    <input
                      type="text"
                      name="empresa"
                      placeholder="EMPRESA"
                      value={formData.empresa}
                      onChange={handleChange}
                    />
                    {errors.empresa && <span className="error">{errors.empresa}</span>}
                  </div>
                </div>

                <div className="form-row">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn-enviar"
                  >
                    {loading ? "ENVIANDO..." : "INSCREVA-SE AGORA"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showPopup && (
        <div className="popup-overlay" onClick={handleOutsideClick}>
          <div className="popup">
            <div className="close-popup">
              <button onClick={() => setShowPopup(false)}>
                X
              </button>
            </div>
            <h2>Cadastro <span>recebido! 🎉</span> </h2>
            <p>
              Em breve, nosso time entrará em contato para agendar a sua
              conversa. <br /><br />Fique de olho no seu e-mail! 😊
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default LandingPage
